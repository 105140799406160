import _ from 'lodash';
import scientificToDecimal from 'scientific-to-decimal';
import GunbotStorage, { WALLET_ADDRESS } from './gunbot.storage';

export const deepCompare = function () {
  let i, l, leftChain, rightChain;

  if (arguments.length < 1) {
    return true; //Die silently? Don't know how to handle such case
    // throw "Need two or more arguments to compare";
  }

  function compare2Objects(x, y) {
    let p;

    // remember that NaN === NaN returns false
    // and isNaN(undefined) returns true
    if (isNaN(x) && isNaN(y) && typeof x === 'number' && typeof y === 'number') {
      return true;
    }

    // Compare primitives and functions.
    // Check if both arguments link to the same object.
    // Especially useful on the step where we compare prototypes
    if (x === y) {
      return true;
    }

    // Works in case when functions are created in constructor.
    // Comparing dates is a common scenario. Another built-ins?
    // We can even handle functions passed across iframes
    if (
      (typeof x === 'function' && typeof y === 'function') ||
      (x instanceof Date && y instanceof Date) ||
      (x instanceof RegExp && y instanceof RegExp) ||
      (x instanceof String && y instanceof String) ||
      (x instanceof Number && y instanceof Number)
    ) {
      return x.toString() === y.toString();
    }

    // At last checking prototypes as good as we can
    if (!(x instanceof Object && y instanceof Object)) {
      return false;
    }

    if (x.isPrototypeOf(y) || y.isPrototypeOf(x)) {
      return false;
    }

    if (x.constructor !== y.constructor) {
      return false;
    }

    if (x.prototype !== y.prototype) {
      return false;
    }

    // Check for infinitive linking loops
    if (leftChain.indexOf(x) > -1 || rightChain.indexOf(y) > -1) {
      return false;
    }

    // Quick checking of one object being a subset of another.
    // todo: cache the structure of arguments[0] for performance
    for (p in y) {
      if (y.hasOwnProperty(p) !== x.hasOwnProperty(p)) {
        return false;
      } else if (typeof y[p] !== typeof x[p]) {
        return false;
      }
    }

    for (p in x) {
      if (y.hasOwnProperty(p) !== x.hasOwnProperty(p)) {
        return false;
      } else if (typeof y[p] !== typeof x[p]) {
        return false;
      }

      switch (typeof x[p]) {
        case 'object':
        case 'function':
          leftChain.push(x);
          rightChain.push(y);

          if (!compare2Objects(x[p], y[p])) {
            return false;
          }

          leftChain.pop();
          rightChain.pop();
          break;

        default:
          if (x[p] !== y[p]) {
            return false;
          }
          break;
      }
    }

    return true;
  }

  for (i = 1, l = arguments.length; i < l; i++) {
    leftChain = []; //Todo: this can be cached
    rightChain = [];

    if (!compare2Objects(arguments[0], arguments[i])) {
      return false;
    }
  }

  return true;
};

export function isEqualTwoArrays(a, b) {
  if (a.length !== b.length) {
    return false;
  } else {
    for (let i = 0; i < a.length; i++) {
      if (a[i] !== b[i]) {
        return false;
      }
    }
    return true;
  }
}

export const dateToDateString = function (date) {
  date = new Date(date);
  return date
    .toLocaleDateString('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      hour12: false,
      minute: '2-digit',
      second: '2-digit',
    })
    .slice(0, -10);
};

export function getDateTimeString(timestamp) {
  const date = new Date(parseFloat(timestamp));
  const hours = date.getHours();
  const minutes = '00' + date.getMinutes();
  const day = date.getDate();
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  const month = months[date.getMonth()];
  const year = date.getFullYear();
  return hours + ':' + minutes.substr(-2) + '  ' + day + '/' + month + '/' + year;
}

export const dateRangeString = function (dateRange = {}) {
  const today = new Date();
  return dateToDateString(dateRange.startDate || today) + ' ~ ' + dateToDateString(dateRange.endDate || today);
};

function getMonday(d) {
  d = new Date(d);
  const day = d.getDay(),
    diff = d.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is sunday
  d.setDate(diff);
  d.setHours(0, 0, 0, 0);
  return d;
}

function getSunday(d) {
  return new Date(getMonday(d).getTime() + 7 * 24 * 3600 * 1000 - 1);
}

export const getDateRangeOfThisWeek = function () {
  const now = Date.now();
  return {
    startDate: getMonday(now),
    endDate: getSunday(now),
  };
};

export const getDateRangeOfThisMonth = function () {
  const date = new Date(),
    y = date.getFullYear(),
    m = date.getMonth();
  return {
    startDate: new Date(y, m, 1),
    endDate: new Date(new Date(y, m + 1, 1).getTime() - 1),
  };
};

export function getDateRangeOfLast30Days() {
  const now = new Date();
  let endDate = now.setHours(23, 59, 59, 999);
  return {
    startDate: new Date(endDate - 30 * 24 * 3600 * 1000 + 1),
    endDate: new Date(endDate),
  };
}

export function getDateRangeOfTournament(startT, testT, endT) {
  console.log(startT, testT, endT);
  const now = Date.now();
  let start = new Date(testT);
  if (now >= testT) {
    start = new Date(startT);
  }
  let end = new Date(endT);
  return {
    startDate: start,
    endDate: end,
  };
}

export function truncate(numToBeTruncated, numOfDecimals) {
  //truncates to precision without rounding up or down
  if (_.isNil(numToBeTruncated)) {
    return 0;
  }
  if (numToBeTruncated === 0) {
    return 0;
  }
  const theNumber = numToBeTruncated.toString();
  const pointIndex = theNumber.indexOf('.');
  return +theNumber.slice(0, pointIndex > -1 ? ++numOfDecimals + pointIndex : undefined);
}

export const countDecimals = value => {
  if (Math.floor(value) !== value) {
    const x = value.toString().split('.');
    if (x.length <= 1) {
      return 0;
    }
    return x[1].length || 0;
  }

  return 0;
};

export const getValueColor = value => {
  if (_.isString(value)) {
    if (value === 'loading' || value === 'n/a') {
      return '#e7e7e7';
    } else if (value.includes('%')) {
      const parsedValue = parseFloat(value);
      if (parsedValue < 0) {
        return '#f13c1d';
      } else if (parsedValue == 0) {
        return '#e7e7e7';
      } else {
        return '#56ca00';
      }
    } else if (value.includes('-')) {
      return '#f13c1d';
    } else {
      return '#56ca00';
    }
  }
  if (_.isNil(value)) {
    value = 0;
  }
  if (value < 0) {
    return '#f13c1d';
  } else if (value == 0) {
    return '#e7e7e7';
  } else {
    return '#56ca00';
  }
};

export function getLanguageFromURL() {
  const regex = new RegExp('[\\?&]lang=([^&#]*)');
  const results = regex.exec(window.location.search);
  return results === null ? null : decodeURIComponent(results[1].replace(/\+/g, ' '));
}

export function sendTokenViaSocket(socket) {
  socket.emit('login', { token: localStorage.getItem('token'), walletAddress: GunbotStorage.get(WALLET_ADDRESS, '') });
}

export function dateToDateTimeString(date) {
  date = new Date(date);
  return date.toLocaleDateString('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    hourCycle: 'h23',
    minute: '2-digit',
    second: '2-digit',
  });
}

export function numFormatter(num = 0) {
  if (num !== 0 && !num) return NaN;
  let absNum = Math.abs(num);
  if (absNum >= 1000000) {
    return (num / 1000000).toLocaleString('en-US', { maximumFractionDigits: 3 }) + 'M';
  } else if (absNum < 1000000 && absNum > 999) {
    return (num / 1000).toLocaleString('en-US', { maximumFractionDigits: 3 }) + 'K';
  } else if (absNum < 900 && absNum >= 1) {
    return num.toLocaleString('en-US', { maximumFractionDigits: 2 });
  } else {
    return toDecimal(num);
  }
}

export function toDecimal(num = 0, dp = 8) {
  if (num !== 0 && !num) return NaN;
  return scientificToDecimal((+num).toFixed(dp));
}

export function toDateLocalString(date) {
  date = new Date(date);
  return date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear();
}

export function getDecimalsInFloat(values, defaultLength = 0) {
  let maxY = Math.max(...values);
  let minY = Math.min(...values);
  let diff = Math.abs(maxY - minY);
  let decimalsInFloat = defaultLength;
  if (diff == 0) {
    decimalsInFloat = 0;
  } else if (diff < 1) {
    decimalsInFloat = Math.abs(Math.floor(Math.log10(diff))) + 1;
  }
  return decimalsInFloat;
}

export async function copyToClipboard (url) {
  try {
      if (navigator.clipboard) {
          await navigator.clipboard.writeText(url);
      } else {
          // Fallback method for browsers that don't support Clipboard API
          const textArea = document.createElement("textarea");
          textArea.value = url;
          document.body.appendChild(textArea);
          textArea.select();
          document.execCommand('copy');
          document.body.removeChild(textArea);
      }
  } catch (err) {
      console.error('Failed to copy text to clipboard: ', err);
  }
};