import $scope from 'consts/scope';
const _ = require('lodash');

export function getExchangePrettyName(exchange) {
  
  let subExchange = exchange;
  let hasHash = _.includes(exchange, "#");
  
  if (hasHash) {
    subExchange = exchange.split("#")[0];
  }
  
  let prettyName = $scope.exchangesWithUsableTickers?.[subExchange]?.prettyName;
  
  if (hasHash) {
    let hashPart = exchange.split("#")[1];
    prettyName = prettyName ? `${prettyName} #${hashPart}` : subExchange + " #" + hashPart;
  } else {
    prettyName = prettyName || subExchange;
  }

  return prettyName;
}
